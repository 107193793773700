

.ig-color-primary {
  color: var(--primary-color);
}
.ig-background-primary {
  background-color: var(--primary-color);
}

.ig-color-primary-text {
  color: var(--primary-text-color);
}
.ig-background-primary-text {
  background-color: var(--primary-text-color);
}

.ig-color-primary-hover {
  color: var(--primary-color-hover);
}
.ig-background-primary-hover {
  background-color: var(--primary-color-hover);
}

.ig-color-primary-dark {
  color: var(--primary-dark-color);
}
.ig-background-primary-dark {
  background-color: var(--primary-dark-color);
}

.ig-color-primary-darker {
  color: var(--primary-darker-color);
}
.ig-background-primary-darker {
  background-color: var(--primary-darker-color);
}

.ig-background-primary-rgba-10 {
  background-color: var(--primary-color-rgba10);
}
.ig-background-primary-rgba-20 {
  background-color: var(--primary-color-rgba20);
}
.ig-background-primary-rgba-30 {
  background-color: var(--primary-color-rgba30);
}
.ig-background-primary-rgba-40 {
  background-color: var(--primary-color-rgba40);
}
.ig-background-primary-rgba-50 {
  background-color: var(--primary-color-rgba50);
}
.ig-background-primary-rgba-60 {
  background-color: var(--primary-color-rgba60);
}
.ig-background-primary-rgba-70 {
  background-color: var(--primary-color-rgba70);
}
.ig-background-primary-rgba-80 {
  background-color: var(--primary-color-rgba80);
}
.ig-background-primary-rgba-90 {
  background-color: var(--primary-color-rgba90);
}

.ig-color-secondary {
  color: var(--secondary-color);
}
.ig-background-secondary {
  background-color: var(--secondary-color);
}

.ig-color-accent {
  color: var(--accent-color);
}
.ig-background-accent {
  background-color: var(--accent-color);
}

.ig-color-header-background {
  color: var(--header-background-color);
}
.ig-background-header-background {
  background-color: var(--header-background-color);
}

.ig-color-header-form {
  color: var(--header-form-color);
}
.ig-background-header-form {
  background-color: var(--header-form-color);
}

.ig-color-header-form-background {
  color: var(--header-form-background-color);
}
.ig-background-header-form-background {
  background-color: var(--header-form-background-color);
}

.ig-color-header-form-placeholder {
  color: var(--header-form-placeholder-color);
}
.ig-background-header-form-placeholder {
  background-color: var(--header-form-placeholder-color);
}

.ig-headlines-color {
  color: var(--headlines-color);
}

.ig-link-color {
  color: var(--link-color);
}


.ig-icons-color {
  color: var(--icons-color);
}

.ig-button-color {
  color: var(--button-color);
}

.ig-cta-button-background-color {
  color: var(--cta-button-background-color);
}

.ig-pricebox-color-background {
  color: var(--pricebox-color-background);
}


.ig-color-text {
  color: var(--ig-text-color);
}
.ig-background-text {
  background-color: var(--ig-text-color);
}

.ig-color-text-secondary {
  color: var(--text-secondary-color);
}
.ig-background-text-secondary {
  background-color: var(--text-secondary-color);
}

