@import 'panelbox.vars';
@import 'breakpoint';
@import 'foundations_vars';

// Outer Box
.ig-panelbox {
  padding: $igPanelboxOuterPadding;
  margin: $igPanelboxMargin;
  background: var(--ig-color-form-clickarea-background);
  color: var(--ig-color-form-clickarea-foreground-light);

  &.ig-panelbox--no-padding {
    padding: 0;
  }

  &.ig-panelbox--no-side-border {
    margin: $igPanelboxMargin 0;
    padding: $igPanelboxOuterPadding 0;
  }

  &.ig-panelbox--margin-bottom {
    margin-bottom: $igPanelboxOuterPadding;
  }

  &.ig-panelbox--attached-top {
    padding-top: 0;
  }
}

// Basic Flexbox + Padding Fix
@mixin ig-panelbox-grid {
  display: flex;
  flex-direction: column;

  // Bottom Padding Fix for Standalone Grids
  &:not(.ig-panelbox) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  > .ig-panelbox__inner {
    width: 100%;
  }
}

/**
 * Grid Modules.
 */
.ig-panelbox--grid2 {
  @include ig-panelbox-grid();

  @media screen and (min-width: $igMediaMedium) {
    flex-direction: row;
    > .ig-panelbox__inner {
      width: 33%;
      margin: 0 0 0 10px;

      &:first-child {
        width: calc(66% + 10px);
        margin: 0;
      }
    }
  }
}
.ig-panelbox--grid2-large {
  @include ig-panelbox-grid();

  @media screen and (min-width: $igMediaLarge) {
    flex-direction: row;
    > .ig-panelbox__inner {
      width: 33%;
      margin: 0 0 0 10px;

      &:first-child {
        width: calc(66% + 10px);
        margin: 0;
      }
    }
  }
}

.ig-panelbox--grid3 {
  @include ig-panelbox-grid();

  @media screen and (min-width: $igMediaMedium) {
    flex-direction: row;
    > .ig-panelbox__inner {
      width: 33%;
      margin: 0 0 0 10px;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.ig-panelbox--grid3-large {
  @include ig-panelbox-grid();

  @media screen and (min-width: $igMediaLarge) {
    flex-direction: row;
    > .ig-panelbox__inner {
      width: 33%;
      margin: 0 0 0 10px;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.ig-panelbox__inner {
  padding: $igPanelboxInnerPadding;
  margin: $igPanelboxInnerMargin;
  background: var(--ig-color-white);

  &--no-padding {
    padding: 0;
  }


  &:last-child {
    margin-bottom: 0;
  }



  &--no-bg {
    background: none;
  }

  .ig-panelbox-fullwidth-child {
    margin-left: ($igPanelboxInnerPadding * -1) !important;
    margin-right: ($igPanelboxInnerPadding * -1) !important;
  }

  .p-field:last-child {
    margin-bottom: 0;
  }
}

.ig-panelbox__header {
  display: flex;
  flex-direction: column;
  @include respond-to(medium up) {
    justify-content: space-between;
    flex-direction: row;
    z-index: 1;
  }

  .ig-panelbox__header-options {
    @include respond-to(medium up) {
      order: 1;
    }

    .icon-button {
      display: inline-block;
      line-height: 45px;
      width: 45px;
      text-align: center;

      &:hover, &:focus-within {
        cursor: pointer;
        color: var(--ig-color-midnight-blue);
      }
    }

    .fas {
      margin: auto;
    }
  }

  h1 {
    .fa-star {
      font-size: 80%;
      margin-right: 5px;
    }

    @include respond-to(medium up) {
      order: 0;
      line-height: 47px;
    }
  }
}
/*
.ig-panelbox__header-light {
  padding-bottom: 10px;

  span {
    font-size: 16px;
    color: var(--ig-color-label);
    font-weight: 400;
  }

  h5 {
    margin: 0;
    padding: 0;
  }
}
*/

.ig-panelbox__content {
  @media screen and (min-width: $igMediaLarge) {
    max-width: 800px;
    .p-accordion-content {
      max-width: 800px;
    }
  }
}
