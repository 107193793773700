


/**
* Classes
*/

body {
  // Colors
  .ig-color-midnight-blue {
    color: var(--ig-color-midnight-blue);
  }

  .ig-color-medium-blue {
    color: var(--ig-color-medium-blue);
  }
  .ig-color-medium-blue-60 {
    color: var(--ig-color-medium-blue60);
  }
  .ig-color-medium-blue-40 {
    color: var(--ig-color-medium-blue40);
  }
  .ig-color-medium-blue-20 {
    color: var(--ig-color-medium-blue20);
  }
  .ig-color-medium-blue-hover {
    color: var(--ig-color-medium-blue-hover);
  }

  .ig-color-sky-blue {
    color: var(--ig-color-sky-blue);
  }
  .ig-color-sky-blue-60 {
    color: var(--ig-color-sky-blue60);
  }
  .ig-color-sky-blue-40 {
    color: var(--ig-color-sky-blue40);
  }
  .ig-color-sky-blue-20 {
    color: var(--ig-color-sky-blue20);
  }
  .ig-color-sky-blue-hover {
    color: var(--ig-color-sky-blue-hover);
  }

  .ig-color-light-blue {
    color: var(--ig-color-light-blue);
  }

  .ig-color-coral-red {
    color: var(--ig-color-coral-red);
  }
  .ig-color-coral-red-60 {
    color: var(--ig-color-coral-red60);
  }
  .ig-color-coral-red-40 {
    color: var(--ig-color-coral-red40);
  }
  .ig-color-coral-red-hover {
    color: var(--ig-color-coral-red-hover);
  }

  /**
  * System Colors
  */
  .ig-color-warning-red {
    color: var(--ig-color-warning-red);
  }
  .ig-color-warning-red-light {
    color: var(--ig-color-warning-red-light);
  }

  .ig-color-hint-orange {
    color: var(--ig-color-hint-orange);
  }

  .ig-color-success-green {
    color: var(--ig-color-success-green);
  }
  .ig-color-success-green-light {
    color: var(--ig-color-success-green-light);
  }
  .ig-color-success-green-hover {
    color: var(--ig-color-success-green-hover);
  }

  .ig-color-note-yellow {
    color: var(--ig-color-note-yellow);
  }

  /**
  * Shades
  */
  .ig-color-pitch-black {
    color: var(--ig-color-pitch-black);
  }

  .ig-color-label {
    color: var(--ig-color-label);
  }
  .icons-color {
    color: var(--icons-color);
  }

  /**
  * Backgrounds
  */
  // Colors

  .ig-background-midnight-blue {
    background-color: var(--ig-color-midnight-blue);
  }

  .ig-background-warning-red-light {
    background-color: var(--ig-color-warning-red-light);
  }
  .ig-background-success-green-light {
    background-color: var(--ig-color-success-green-light);
  }
  .ig-background-note-yellow {
    background-color: var(--ig-color-note-yellow);
  }




  /**
  * Typographioe Helper Classes
  */
  .ig-color-primary {
    color: var(--primary-color)  !important;
  }

  .ig-color-primary-dark {
    color: var(--primary-dark-color) !important;
  }
}
