.input-w-icon {
    position: relative;

    i {
        position: absolute;
        top: 13px;
        color: var(--text-secondary-color);
        font-size: 16px;
    }

    &.icon-left {
        input {
            padding-left: 30px;
        }

        i {
            left: 6px;
        }
    }

    &.icon-right {
        input {
            padding-right: 30px;
        }

        i {
            right: 6px;
        }
    }
}
