// Panel Class to Root node activates the panel Styles
&.ig-panel,
.ig-panel {
  @include igTypoContent();

  a {
    color: var(--link-color);
  }


  h1 {
    @include igTypoHeadline("size-11", "bold");
  }

  h2 {
    @include igTypoHeadline("size-09", "bold");
  }

  h3 {
    @include igTypoHeadline("size-07", "bold");
  }

  h4 {
    @include igTypoHeadline("size-06", "bold");
  }

  h5 {
    @include igTypoHeadline("size-05", "bold");
  }

  h6 {
    @include igTypoHeadline("size-05", "bold");
  }


  strong,
  .ig-text-strong {
    font-weight: $font-weight-bold;
  }

  small,
  .ig-text-small {
    font-size: $font-size-content-small;
  }

  .ig-text-large,
  .ig-text-intro {
    font-size: $font-size-content-large;
  }

  .ig-text-tiny {
    font-size: $font-size-content-tiny;
  }

  label,
  .ig-label {
    font-size: $font-size-form-label;
    font-weight: $font-weight-normal;

    &.ig-label--is-selected {
      font-weight: $font-weight-bold;
    }
  }
  .ig-label-title {
    font-size: $font-size-form-label-large;
    font-weight: $font-weight-bold;
    display: block;

    sup {
      vertical-align: text-top;
    }
  }

}
