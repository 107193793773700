@use "sass:map";

@function rem-calc($size, $base: 16px) {
    $remSize: calc($size / $base);
    @return #{$remSize}rem;
}

// Brand Typographie (Panel)
// Design Tokens
$font-family-content: var(--skin-font-family, Roboto);
$font-family-headline: var(--skin-font-family-headline);

$base-font-size: 14px;
$base-line-height: 1.57;
$icon-sizes: (
    "size-01": rem-calc(24px)
);
$font-sizes: (
    "size-01": rem-calc(10px),
    "size-02": rem-calc(12px),
    "size-03": rem-calc(13px),
    "size-04": rem-calc(14px),
    "size-05": rem-calc(16px),
    "size-06": rem-calc(18px),
    "size-07": rem-calc(20px),
    "size-08": rem-calc(22px),
    "size-09": rem-calc(26px),
    "size-11": rem-calc(36px),
    "size-12": rem-calc(42px)
);
$font-weights: (
    "light": 300,
    "normal": 400,
    "bold": 500
);

// general
$font-weight-light: map.get($font-weights, "light");
$font-weight-normal: map.get($font-weights, "normal");
$font-weight-bold: map.get($font-weights, "bold");

$font-size-content-tiny: map.get($font-sizes, "size-01");
$font-size-content-small: map.get($font-sizes, "size-02");
$font-size-content-default: map.get($font-sizes, "size-04");
$font-size-content-large: map.get($font-sizes, "size-05");


$font-size-card-highlightValue: map.get($font-sizes, "size-06");
$font-size-emptyMessage: map.get($font-sizes, "size-06");


// tooltip
$font-size-tooltip: map.get($font-sizes, "size-02");
$line-height-tooltip: 1.33;



// navigation
$font-size-menu-firstLevel: map.get($font-sizes, "size-04");
$font-size-menu-secondLevel: map.get($font-sizes, "size-03");
$font-size-sideNavigation: map.get($font-sizes, "size-03");
$font-size-sideNavigation-title: map.get($font-sizes, "size-01");

$icon-size-menu: map.get($icon-sizes, "size-01");


$font-size-cloudMenu-top: map.get($font-sizes, "size-05");
$font-size-cloudMenu-entries: map.get($font-sizes, "size-05");
$font-size-cloudMenu-entries-title: map.get($font-sizes, "size-01");
$font-size-cloudMenu-editableEntries: map.get($font-sizes, "size-04");
$font-size-cloudMenu-editableEntries-title: map.get($font-sizes, "size-04");
$font-size-cloudMenu-countLabel: map.get($font-sizes, "size-01");




// object card
$font-size-objectCard-label: map.get($font-sizes, "size-04");
$font-size-objectCard-value: map.get($font-sizes, "size-05");
$font-size-objectCard-bigValue: map.get($font-sizes, "size-06");
$font-size-objectCard-smallMode-label: map.get($font-sizes, "size-02");
$font-size-objectCard-smallMode-value: map.get($font-sizes, "size-04");
$font-size-objectCard-smallMode-bigValue: map.get($font-sizes, "size-05");



// pricebox
$font-size-priceBox-bottomBar-text: map.get($font-sizes, "size-05");
$font-size-priceBox-bottomBar-smallText: map.get($font-sizes, "size-02");
$font-size-priceBox-bottomBar-largeText: map.get($font-sizes, "size-08");



// action card
$font-size-actionCard-title: map.get($font-sizes, "size-07");
$font-size-actionCard-smallMode-title: map.get($font-sizes, "size-04");



// forms
$font-size-form-label: map.get($font-sizes, "size-04");
$font-size-form-input-large: map.get($font-sizes, "size-05");
$font-size-form-label-large: map.get($font-sizes, "size-05");
$font-size-form-floatingLabel-filled: map.get($font-sizes, "size-02");