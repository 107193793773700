* {
	box-sizing: border-box;
}

.p-component {
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeight;
}

.p-component-overlay {
    background-color: $maskBg;
    transition-duration: $transitionDuration;
}

:not(.p-autocomplete) {
  &.p-disabled, &.p-component:disabled {
    opacity: $disabledOpacity;
  }
}


.p-error, .p-invalid {
    color: $errorColor;
}

.p-text-secondary {
    color: var(--text-secondary-color);
}

.pi {
    font-size: $primeIconFontSize;
}

.p-link {
    font-size: $fontSize;
    font-family: $fontFamily;
    border-radius: $borderRadius;

    &:focus {
        @include focused();
    }
}
