.p-organizationchart {
    .p-organizationchart-node-content {
        &.p-organizationchart-selectable-node:not(.p-highlight):hover,
        &.p-organizationchart-selectable-node:not(.p-highlight):focus-within {
            background: $inputListItemHoverBg;
            color: $inputListItemTextHoverColor;
        }

        &.p-highlight {
            background: $highlightBg;
            color: $highlightTextColor;

            .p-node-toggler {
                i {
                    color: var(--primary-color-darker20);
                }
            }
        }
    }

    .p-organizationchart-line-down {
        background: $organizationChartConnectorColor;
    }

    .p-organizationchart-line-left {
        border-right: $panelContentBorder;
        border-color: $organizationChartConnectorColor;
    }

    .p-organizationchart-line-top {
        border-top: $panelContentBorder;
        border-color: $organizationChartConnectorColor;
    }

    .p-organizationchart-node-content {
        border: $panelContentBorder;
        background: $panelContentBg;
        color: $panelContentTextColor;
        padding: $panelContentPadding;
    }

    .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;

        &:focus {
            @include focused();
        }
    }
}
