/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.562rem 1rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.857rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0.562rem 0;

/// Background of a button
/// @group button
$buttonBg: var(--button-color);

/// Text color of a button
/// @group button
$buttonTextColor: var(--primary-text-color);

// font size of a button
$buttonFontSize: 14px;

/// Border of a button
/// @group button
$buttonBorder: 1px solid var(--button-color);

/// Background of a button in hover state
/// @group button
$buttonHoverBg: var(--button-hover-color);

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: var(--primary-text-color);

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: var(--button-hover-color);

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: var(--button-hover-color);

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: var(--primary-text-color);

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: var(--button-hover-color);

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px #c4e2e7, 0px 2px 2px 0px #c4e2e7;

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: 0.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder: 1px solid var(--button-color);

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: #6c757d;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: #e9ecef;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: #dee2e6;

/// Background of a secondary button
/// @group button
$secondaryButtonBg: transparent;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: var(--button-color);

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid var(--button-color);

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: var(--button-hover-color);

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: #ffffff;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: #546e7a;

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: #455a64;

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: #ffffff;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: #455a64;

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem #b0bec5;

/// Background of an info button
/// @group button
$infoButtonBg: #03a9f4;

/// Text color of an info button
/// @group button
$infoButtonTextColor: #ffffff;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid #03a9f4;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: #039be5;

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: #ffffff;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: #039be5;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: #0288d1;

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: #ffffff;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: #0288d1;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: var(--cta-button-background-color);

/// Text color of a success button
/// @group button
$successButtonTextColor: var(--cta-button-color);

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid var(--cta-button-background-color);

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: var(--cta-button-background-color-hover);

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: #ffffff;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: var(--cta-button-background-color-hover);

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: var(--cta-button-background-color-hover);

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: #ffffff;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: var(--cta-button-background-color-hover);

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem $successButtonBg;

/// Background of a warning button
/// @group button
$warningButtonBg: #ffc107;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: var(--text-color);

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid #ffc107;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: #ffb300;

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: var(--text-color);

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: #ffb300;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: #ffa000;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: var(--text-color);

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: #ffa000;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg: #9c27b0;

/// Text color of a help button
/// @group button
$helpButtonTextColor: #ffffff;

/// Border of a help button
/// @group button
$helpButtonBorder: 1px solid #9c27b0;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: #8e24aa;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: #ffffff;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: #8e24aa;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: #7b1fa2;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: #ffffff;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: #7b1fa2;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: 0 0 0 0.2rem #ce93d8;

/// Background of a danger button
/// @group button
$dangerButtonBg: $buttonBg;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: $buttonTextColor;

/// Border of a danger button
/// @group button
$dangerButtonBorder: $buttonBorder;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: $buttonHoverBg;

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: $buttonTextHoverColor;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: $buttonHoverBorderColor;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: $buttonActiveBg;

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: $buttonTextActiveColor;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: $buttonActiveBorderColor;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Text color of a link button
/// @group button
$linkButtonColor: var(--primary-darker-color);

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: var(--primary-darker-color);

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration: underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: var(--ig-color-form-clickarea-background);

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid var(--ig-color-form-clickarea-background);

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: var(--ig-color-form-clickarea-foreground-light);

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: var(--ig-color-form-clickarea-foreground-light);

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: var(--ig-color-form-clickarea-background-hover);

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: var(--ig-color-form-clickarea-background-hover);

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: var(--ig-color-form-clickarea-foreground-light);

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: var(--ig-color-form-clickarea-foreground-light);

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: var(--ig-color-form-clickarea-background-highlight);

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: var(--ig-color-form-clickarea-background-highlight);

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: var(--ig-color-form-clickarea-foreground-light-highlight);

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: var(--ig-color-form-clickarea-foreground-light-highlight);

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: var(--ig-color-form-clickarea-background-highlight);

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: var(--ig-color-form-clickarea-background-highlight);

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: var(--ig-color-form-clickarea-foreground-light-highlight);

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: var(--ig-color-form-clickarea-foreground-light-highlight);
