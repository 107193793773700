@import 'foundations_vars';
/// Border of a panel header
/// @group panel
$panelHeaderBorder: 1px solid var(--ig-color-white);

/// Background of a panel header
/// @group panel
$panelHeaderBg: var(--ig-color-menu-background);

/// Text color of a panel header
/// @group panel
$panelHeaderTextColor: var(--ig-color-menu-foreground);

/// Font weight of a panel header
/// @group panel
$panelHeaderFontWeight: 600;

/// Padding of a panel header
/// @group panel
$panelHeaderPadding: 1rem;

/// Font weight of a toggleable panel header
/// @group panel
$panelToggleableHeaderPadding: 0.5rem 1rem;

/// Background of a panel header in hover state e.g. toggleable state
/// @group panel
$panelHeaderHoverBg: var(--ig-color-menu-background);

/// Border color of a panel header in hover state e.g. toggleable state
/// @group panel
$panelHeaderHoverBorderColor: var(--ig-color-card-border);

/// Text color of a panel header in hover state e.g. toggleable state
/// @group panel
$panelHeaderTextHoverColor: var(--ig-color-menu-foreground);

/// Border for the content section of a panel
/// @group panel
$panelContentBorder: 1px solid var(--ig-color-card-border);

/// Background for the content section of a panel
/// @group panel
$panelContentBg: var(--ig-color-menu-background);

/// Text color for the content section of a panel
/// @group panel
$panelContentTextColor: var(--ig-color-menu-foreground);

/// Padding for the content section of a panel
/// @group panel
$panelContentPadding: 1rem;

/// Border for the footer section of a panel
/// @group panel
$panelFooterBorder: 1px solid var(--ig-color-card-border);

/// Background for the footer section of a panel
/// @group panel
$panelFooterBg: var(--ig-color-menu-background);

/// Text color for the footer section of a panel
/// @group panel
$panelFooterTextColor: var(--ig-color-menu-foreground);

/// Padding for the footer section of a panel
/// @group panel
$panelFooterPadding: 0.5rem 1rem;

/// Spacing between to accordion panels
/// @group panel
$accordionSpacing: 0;

/// Border of an accordion panel header
/// @group panel
$accordionHeaderBorder: $panelHeaderBorder;

/// Background of an accordion panel header
/// @group panel
$accordionHeaderBg: var(--ig-color-accordion-header-background);

/// Text color of an accordion panel header
/// @group panel
$accordionHeaderTextColor: var(--ig-color-accordion-header-foreground);

/// Font size of an accordion panel header
/// @group panel
$accordionHeaderFontSize: 16px;

/// Font weight of an accordion panel header
/// @group panel
$accordionHeaderFontWeight: 500;

/// Padding of an accordion panel header
/// @group panel
$accordionHeaderPadding: 17px 15px;

/// Background of an accordion panel header in hover state
/// @group panel
$accordionHeaderHoverBg: var(--ig-color-accordion-header-hover-background);

/// Border of an accordion panel header in hover state
/// @group panel
$accordionHeaderHoverBorderColor: var(--ig-color-accordion-header-hover-background);

/// Text color of an accordion panel header in hover state
/// @group panel
$accordionHeaderTextHoverColor: var(--ig-color-accordion-header-foreground);

/// Background of an accordion panel header in expanded state
/// @group panel
$accordionHeaderActiveBg: var(--ig-color-accordion-header-active-background);

/// Border of an accordion panel header in expanded state
/// @group panel
$accordionHeaderActiveBorderColor: var(--ig-color-accordion-header-active-background);

/// Text color of an accordion panel header in expanded state
/// @group panel
$accordionHeaderTextActiveColor: var(--ig-color-accordion-header-active-foreground);

/// Hover background of an accordion panel header in expanded state
/// @group panel
$accordionHeaderActiveHoverBg: var(--ig-color-accordion-header-active-background);

/// Hover border of an accordion panel header in expanded state
/// @group panel
$accordionHeaderActiveHoverBorderColor: var(--ig-color-accordion-header-active-background);

/// Text color of an accordion panel header in expanded state
/// @group panel
$accordionHeaderTextActiveHoverColor: var(--ig-color-accordion-header-active-foreground);

/// Border for a content section of an accordion panel
/// @group panel
$accordionContentBorder: var(--ig-color-accordion-header-active-background);

/// Background for a content section of an accordion panel
/// @group panel
$accordionContentBg: transparent;

/// Text color for a content section of an accordion panel
/// @group panel
$accordionContentTextColor: var(--ig-text-color);

/// Padding for a content section of an accordion panel
/// @group panel
$accordionContentPadding: 0 15px 15px;

/// Border for a parent element containing all the headers of a tabview
/// @group panel
$tabviewNavBorder: 1px solid var(--ig-color-card-border);

/// Border width for a parent element containing all the headers of a tabview
/// @group panel
$tabviewNavBorderWidth: 0 0 2px 0;

/// Background for a parent element containing all the headers of a tabview
/// @group panel
$tabviewNavBg: var(--ig-color-white);

/// Spacing between tabview headers
/// @group panel
$tabviewHeaderSpacing: 0;

/// Border of a tabview header
/// @group panel
$tabviewHeaderBorder: solid var(--ig-color-card-border);

/// Border width of a tabview header
/// @group panel
$tabviewHeaderBorderWidth: 0 0 2px 0;

/// Border color of a tabview header
/// @group panel
$tabviewHeaderBorderColor: transparent transparent var(--ig-color-card-border) transparent;

/// Background of a tabview header
/// @group panel
$tabviewHeaderBg: var(--ig-color-white);

/// Text color of a tabview header
/// @group panel
$tabviewHeaderTextColor: var(--text-secondary-color);

/// Font weight of a tabview header
/// @group panel
$tabviewHeaderFontWeight: $panelHeaderFontWeight;

/// Padding of a tabview header
/// @group panel
$tabviewHeaderPadding: $panelHeaderPadding;

/// Margin of a tabview header
/// @group panel
$tabviewHeaderMargin: 0 0 -2px 0;

/// Background of a tabview header in hover state
/// @group panel
$tabviewHeaderHoverBg: var(--ig-color-white);

/// Border of a tabview header in hover state
/// @group panel
$tabviewHeaderHoverBorderColor: var(--ig-color-card-border);

/// Text color of a tabview header in hover state
/// @group panel
$tabviewHeaderTextHoverColor: var(--text-secondary-color);

/// Background of a tabview header in selected state
/// @group panel
$tabviewHeaderActiveBg: var(--ig-color-white);

/// Border of a tabview header in selected state
/// @group panel
$tabviewHeaderActiveBorderColor: var(--icons-color);

/// Text color of a tabview header in selected state
/// @group panel
$tabviewHeaderTextActiveColor: var(--icons-color);

/// Border for content section of a tabview tab
/// @group panel
$tabviewContentBorder: 0 none;

/// Background for content section of a tabview tab
/// @group panel
$tabviewContentBg: $panelContentBg;

/// Text color for a content section of a tabview tab
/// @group panel
$tabviewContentTextColor: $panelContentTextColor;

/// Padding for a content section of a tabview tab
/// @group panel
$tabviewContentPadding: $panelContentPadding;

/// Background of a fieldset header in hover state
/// @group panel
$panelHeaderHoverBg: $panelContentBg;

/// Border of a fieldset header in hover state
/// @group panel
$panelHeaderHoverBorderColor: var(--ig-color-card-border);

/// Text color of a fieldset header in hover state
/// @group panel
$panelHeaderTextHoverColor: var(--text-color);

/// Border for a track bar of a scroll panel
/// @group panel
$scrollPanelTrackBorder: 0 none;

/// Background for a track bar of a scroll panel
/// @group panel
$scrollPanelTrackBg: var(--ig-color-menu-background);

/// Padding of a card body
/// @group panel
$cardBodyPadding: 1rem;

/// Font size of a card title
/// @group panel
$cardTitleFontSize: 1.5rem;

/// Font weight of a card title
/// @group panel
$cardTitleFontWeight: 700;

/// Font size of a card subtitle
/// @group panel
$cardSubTitleFontWeight: 700;

/// Text color of a card subtitle
/// @group panel
$cardSubTitleColor: var(--text-secondary-color);

/// Padding of a card content
/// @group panel
$cardContentPadding: 1rem 0;

/// Padding of a card footer
/// @group panel
$cardFooterPadding: 1rem 0 0 0;

/// Box shadow of a card
/// @group panel
$cardShadow: var(--box-shadow);

/// Margin of divider in horizontal layout
/// @group panel
$dividerHorizontalMargin: 1rem 0;

/// Padding of divider in horizontal layout
/// @group panel
$dividerHorizontalPadding: 0 1rem;

/// Margin of divider in vertical layout
/// @group panel
$dividerVerticalMargin: 0 1rem;

/// Padding of divider in vertical layout
/// @group panel
$dividerVerticalPadding: 1rem 0;

/// Border width of the divider
/// @group panel
$dividerSize: 1px;

/// Color of the divider border
/// @group panel
$dividerColor: var(--ig-color-card-border);

/// Background of the splitter gutter
/// @group panel
$splitterGutterBg: var(--ig-color-card-border);

/// Background of the splitter gutter handle
/// @group panel
$splitterGutterHandleBg: var(--ig-color-card-border);
