@layer ingrid, primeng;

@import './public/foundations_css';
@import './public/elements_css';
@import './public/components_css';
@import './public/mixins';

@include response-classes();

@import './variables';
@import './designer/components';
@import './_extensions';



//@import './libs/box'; // a box style with different states (@TODO:should once get an own element)
//@import './libs/dialog'; // a dialog (@TODO: should once get an own element)
@import './libs/favorite_switch'; // a favorite toggle (@TODO: should once get an own element)
@import './libs/flex'; // flexbox lib with mixins covering all browsers
@import './libs/input'; // custom input classes (@TODO: should once get an own element)

@import '@gridscale/primeng17/resources/primeng';

// import primeflex foo
@import 'primeflex/src/variables';
@import 'primeflex/src/display';
@import 'primeflex/src/formlayout';
@import 'primeflex/src/grid';
@import 'primeflex/src/spacing';
@import 'primeflex/src/text';
@import 'primeflex/src/flexbox/flexbox';

// prism css for ig-code
@import 'prismjs/themes/prism.css';

@layer ingrid {
  * {
    outline-style: none;
    margin: 0;
    padding: 0;
  }
}

html,
body {
  height: 100%;
}

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--skin-font-family);
  color: var(--ig-text-color);
  p {
    color: var(--ig-text-color);
  }

  &.darkmode {
    color-scheme: dark;
    ::selection {
      background: #eee;
    }
  }
  &.highcontrast {
    a:not(.linkinbutton) {
      // in high contrast mode use default link colors from browser
      color: revert!important;
    }


    // as there are no background colors in forced-colors mode, try to select all elements where one item can be active and use a bold font (product-selector, tabs, filter...)
    [class*="--active"], [class*="--is-active"], [class*="label-active"], [class*="filter-active"], [class*="isActive"], [aria-checked="true"] {
      font-weight: $font-weight-bold !important;
      * {
        font-weight: $font-weight-bold !important;
      }
    }
  }
}


/*
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
  font-weight: normal;
  font-family: var(--skin-font-family), sans-serif !important;
}
*/

label {
  cursor: pointer;
  color: var(--ig-color-label-light);
}

.ig-label-title {
  color: var(--ig-color-label);
}

small {
  color: var(--ig-color-label-light);
}

.relative {
  position: relative;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}


.full-width {
  width: 100%;
}

.no-scrolling {
  overflow: hidden;
}

.p-menuitem-content a {
  text-decoration: none;
}

h1 {
  color: var(--h1-color);
}
h2 {
  color: var(--h2-color);
}
h3 {
  color: var(--h3-color);
}
h4 {
  color: var(--h4-color);
}
h5 {
  color: var(--h5-color);
}
h6 {
  color: var(--h6-color);
}
