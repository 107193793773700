//  White

//Background Image
$igInputTextBackgroundBorderWidth: 1px;
$igInputTextBackgroundPaddingBottom: 8px;
$igInputTextBackgroundPaddingRight: 6px;

//Icon
$igInputTextIconPaddingLeft: 42px;
$igInputTextIconFontSize: 16px;
$igInputTextIconLeft: 10px;

//Icon Right
$igInputTextRightIconPaddingLeft: 14px;
$igInputTextRightIconPaddingRight: 42px;
$igInputTextRightIconRight: 10px;

//Icon with Background
$igInputTextIconBackgroundWidth: 38px;
$igInputTextIconBackgroundLineHeight: 40px;

