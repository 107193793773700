body {
  .ui-inputswitch {
    user-select: none;
    width: 3.6em;
    height: 2em;
    .ui-inputswitch-slider {
      border-radius: 30px;
      &:before {
        height: 26px;
        width: 26px;
        left: 1px;
        bottom: 1px;

        border: none;
      }
    }

    &.ui-inputswitch-checked {
      .ui-inputswitch-slider:before {
        -webkit-transform: translateX(1.6em);
        -ms-transform: translateX(1.6em);
        transform: translateX(1.6em);
      }
    }
  }

  &.highcontrast .p-inputswitch-slider {
    border:var(--high-contrast-border);
  }
}
