@import 'foundations_vars';
@import 'variables/general';
@import 'variables/form';

body {
  // Set Large size to 50px height!
  .p-inputtext.p-inputtext-lg,
  .p-inputtext-lg .p-inputtext {
    font-size: $font-size-form-input-large;
    padding: $inputPaddingLg;
  }

  .p-field.required {
    h5, label, .headline {
      &:after {
        content: '*';
        margin-left: 2px;
      }
    }
  }

  // Invalid icon should be red!
  .p-input-icon-right > i.is-invalid-icon {
    color: var(--ig-color-warning-red) !important;
  }

  label.required {
    &:after {
      content: '*';
      margin-left: 2px;
    }
  }

  label.disabled {
    opacity: $disabledOpacity;
  }

  .invalid-message {
    display: block;
    opacity: 0;
    transition: opacity 0.4s;
    &.visible {
      opacity: 1;
    }
    color: var(--color-red);
    text-align: right;
    font-size: $font-size-content-small;
    height: 12px;
  }

  .p-inputgroup {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--ig-color-label);
      opacity: 1; /* Firefox */
    }
  }

  /**
  Floating Labels in normal an large with autofill fixes
  */
  .p-float-label {
    /*
      Make Background white for a autofill form with a lot of white shadow!
    */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus-within,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important; // chrome
      -webkit-text-fill-color: var(--text-color) !important; // chrome
      filter: none; // Firefox
    }

    input::placeholder {
      opacity: 0;
      transition: inherit;
    }
    input:focus::placeholder {
      opacity: 1;
    }

    label {
      background: var(--ig-color-white);
      padding: 0 5px;
      color: var(--ig-color-form-clickarea-foreground-light) !important;
      text-transform:  none !important; // Fix Label overwirte uppercase
    }

    .ng-invalid.ng-dirty.ng-touched + label {
      color: $inputErrorBorderColor !important;
    }

    .p-inputtext-lg ~ label {
      font-size: $font-size-form-label-large;
    }

    > label {
      left: 5px;
      margin-top: -.5rem; // overwrite to obtain initial value
    }

    input:focus ~ label,
    input:-webkit-autofill ~ label,
    input.p-filled ~ label,
    textarea:focus ~ label,
    textarea.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
      top: 0;
      font-size: $font-size-form-floatingLabel-filled;
    }
    input.p-inputtext-lg:focus ~ label,
    input.p-inputtext-lg.p-filled ~ label,
    .p-inputtext-lg.p-inputwrapper-focus ~ label,
    .p-inputtext-lg.p-inputwrapper-filled ~ label {
      font-size: $font-size-form-floatingLabel-filled;
      color: var(--ig-color-label);
    }
  }



  .p-dropdown {
    &.ig-transparent {
      background: transparent;
    }
  }
}
