$igMediaSmall: 576px;
$igMediaMedium: 768px;
$igMediaLarge: 992px;
$igMediaXLarge: 1200px;

$igPanelboxOuterPadding: 10px;
$igPanelboxMargin: 0 0 10px 0;

$igPanelboxInnerPadding: 25px;
$igPanelboxInnerMargin: 0 0 10px 0;


