//////////////////////////////////////////////
//          COPY OF IG-LOADER               //
//     for initial page load index.html     //
//////////////////////////////////////////////

@import 'foundations_vars';

@keyframes ig-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.ig-loader-container {
  &.fullscreen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    // z-index: 1000;
  }
  &.parent-size {
    position: absolute;
    height: 100%;
    width: 100%;
    // z-index: 50;
  }

  &.fullscreen, &.parent-size {
    top: 0;
    left: 0;
  }

  &.inline {
    display: inline-block;
  }

}

.ig-loader-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: var(--ig-color-viewport-background);

  &.rounded-corners {
    border-radius: 5px;
  }
}

.ig-loader-inner-container {
  .fullscreen &{
    position: absolute;
    // z-index: 1001;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .parent-size & {
    position: absolute;
    // z-index: 51;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 100vh;
  }

  .inline & {
    display: inline-block;
  }

  .inline-container {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: 1em;
    padding-top: 0.25em;
    .has-customsize & {
      padding-top: 0;
    }
    overflow: visible;
    position: relative;
    // overflow: hidden;
  }

  @keyframes ig-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-spinner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    border-color: var(--secondary-color) var(--secondary-color) var(--secondary-color) transparent;
    &.white {
      border: 2px solid var(--ig-color-card-border);
      border-color: var(--ig-color-card-border) var(--ig-color-card-border) var(--ig-color-card-border) transparent;
    }
    animation: ig-loader 1.5s linear infinite;
    margin: 0 auto;

    .fullscreen & {
      width: 100px;
      height: 100px;
      border-width: 4px;
    }

    .parent-size & {
      width: 100px;
      height: 100px;
      border-width: 4px;
    }

    .inline & {
      display: block;
      width: 0.75em;
      height: 0.75em;
    }
  }


  .loading-spinner-new {
    /* loader from https://loading.io/css/ */
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .fullscreen & {
      width: 100px;
      height: 100px;
    }

    .parent-size & {
      width: 100px;
      height: 100px;
    }

    .inline & {
      display: block;
      width: calc(1em + 3px - 0.25em);
      height: calc(1em + 3px - 0.25em);
    }
    .inline.has-customsize & {
      width: calc(1em + 3px);
      height: calc(1em + 3px);
    }

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: calc(95% - 2px);
      height: calc(95% - 2px);
      border: 1px solid var(--primary-color);
      .inline & {
        margin-top: 1px;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
      }

      .fullscreen & {
        border-width: 1vw;
        width: calc(95% - 2vw);
        height: calc(95% - 2vw);
      }

      .parent-size & {
        border-width: 4px;
        width: calc(95% - 8px);
        height: calc(95% - 8px);
      }
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--primary-color) transparent transparent transparent;


      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    &.white div {
      border-color: var(--ig-color-white) transparent transparent transparent;
    }
  }


  .loader-icon {
    display: inline-block;
    animation: ig-loader 1.8s linear infinite;

    .fullscreen & {
      font-size: 25vw;
    }

    .parent-size & {
      font-size: 100px;
    }

    .inline & {
      font-size: inherit;
    }
  }

  .loader-message {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 1em;
    flex: 1 0 100%;
    color: var(--h6-color);
  }
}
