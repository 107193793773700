@import 'foundations_vars';


p-menu.nowrapmenu .p-menuitem {
  white-space: nowrap;
}

body {
  // Steps Menu
  .p-steps {
    .p-steps-item {
      &:before {
        margin-top: 0;
        top: 21px;
      }
      &:first-child:before {
        left: calc(50% + 30px);
      }
      &:last-child:before {
        width: auto;
        right: calc(50% + 30px);
      }

      // Default
      .p-menuitem-link {
        .p-steps-number {
          background: var(--ig-color-form-clickarea-background);
          color: var(--ig-color-form-clickarea-foreground-light);
          font-family: 'FSAlbert';
          font-weight: 400;
          font-size: 1.125rem;
        }
        .p-steps-title {
          font-size: 16px;
          margin-top: 14px;
          color: var(--ig-color-form-clickarea-foreground-bold);
        }
      }

      //Active
      &.p-highlight .p-menuitem-link {
        .p-steps-number {
          background: var(--ig-color-form-clickarea-background-highlight);
          // border-color: var(--primary-color);
          color: var(--ig-color-form-clickarea-foreground-light-highlight);
        }
        .p-steps-title {
          // color: var(--ig-color-form-clickarea-foreground-bold-highlight);
        }
      }

      // Solved
      &.p-steps-item--is-solved {
        .p-menuitem-link {
          .p-steps-number {
            background: var(--ig-color-success-green);
            border-color: var(--ig-color-success-green);
            color: var(--ig-color-white);
            font-family: 'primeicons';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            text-indent: -9999px;
            position: relative;
            &:before {
              content: '\e909';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              text-indent: 0;
            }
          }

          .p-steps-title {
            color: var(--ig-color-success-green);
          }
        }
      }
    }
  }


}
