@import 'foundations_vars';


/// Padding of an inplace element
/// @group misc
$inplacePadding: $inputPadding;

/// Background of an inplace element in hover state
/// @group misc
$inplaceHoverBg: #e9ecef;

/// Text color of an inplace element in hover state
/// @group misc
$inplaceTextHoverColor: var(--text-color);

/// Background of a badge
/// @group misc
$badgeBg: var(--icons-color);

/// Text color of a badge
/// @group misc
$badgeTextColor: var(--primary-text-color);

/// Minimum width of a badge
/// @group misc
$badgeMinWidth: 1.5rem;

/// Height of a badge
/// @group misc
$badgeHeight: 1.5rem;

/// Font weight of a badge
/// @group misc
$badgeFontWeight: 700;

/// Font size of a badge
/// @group misc
$badgeFontSize: 0.625rem;

/// Padding of a badge
/// @group misc
$tagPadding: 0.25rem 0.4rem;

/// Height of a progress bar
/// @group misc
$progressBarHeight: 1.5rem;

/// Border of a progress bar
/// @group misc
$progressBarBorder: 0 none;

/// Background of a progress bar
/// @group misc
$progressBarBg: var(--ig-color-white);

/// Background of a progress bar value
/// @group misc
$progressBarValueBg: var(--icons-color);

/// Background of an avatar
/// @group misc
$avatarBg: #dee2e6;

/// Text color of an avatar
/// @group misc
$avatarTextColor: var(--text-color);

/// Background of a chip
/// @group misc
$chipBg: var(--secondary-color);

/// Text color of a chip
/// @group misc
$chipTextColor: var(--secondary-text-color);

/// Border radius of a chip
/// @group misc
$chipBorderRadius: 20px;

/// Background of a scrollTop
/// @group misc
$scrollTopBg: rgba(0, 0, 0, 0.7);

/// Background of a scrollTop in hover state
/// @group misc
$scrollTopHoverBg: rgba(0, 0, 0, 0.8);

/// Width of a scrollTop
/// @group misc
$scrollTopWidth: 3rem;

/// Height of a scrollTop
/// @group misc
$scrollTopHeight: 3rem;

/// Border radius of a scrollTop
/// @group misc
$scrollTopBorderRadius: 50%;

/// Font size of a scrollTop
/// @group misc
$scrollTopFontSize: 1.5rem;

/// Text color of a scrollTop
/// @group misc
$scrollTopTextColor: #f8f9fa;

/// Background of a skeleton
/// @group misc
$skeletonBg: var(--ig-color-form-clickarea-background);

/// Background of a skeleton animation
/// @group misc
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);
