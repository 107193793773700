@import 'base_variables';

.p-dialog {
    border-radius: $igBorderRadiusL;
    box-shadow: $overlayContainerShadow;
    border: 0px solid;
    background: $overlayContentBg;


    .p-dialog-header {
        border-bottom: $dialogHeaderBorder;
        background: $dialogHeaderBg;
        color: $dialogHeaderTextColor;
        padding: $dialogHeaderPadding;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-dialog-title {
            font-weight: $dialogHeaderFontWeight;
            font-size: $dialogHeaderFontSize;
        }

        .p-dialog-header-icon {
            @include action-icon();
            margin-right: $inlineSpacing;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .p-dialog-content {
        color: $panelContentTextColor;
        padding: $dialogContentPadding;
    }

    .p-dialog-footer {
        border-top:  $dialogFooterBorder;
        background: $overlayContentBg;
        color: $panelFooterTextColor;
        padding: $dialogFooterPadding;
        text-align: right;
        border-bottom-right-radius: $igBorderRadiusL;
        border-bottom-left-radius: $igBorderRadiusL;

        button {
            margin: 0 $inlineSpacing 0 0;
            width: auto;
        }
    }

    &.p-confirm-dialog {
        .p-confirm-dialog-icon {
            font-size: $primeIconFontSize * 2;
        }

        .p-confirm-dialog-message {
            margin-left: $inlineSpacing * 2;
        }
    }
}

.p-dialog-mask.p-component-overlay {
    background-color: $maskBg;
}
