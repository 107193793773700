.ui-toast .ui-toast-icon {
    top: 50%;
    transform: translateY(-50%);
}

.custom_global_post.ui-toast .ui-toast-summary {
    padding: 0px;
}

.ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
    top: 5px;
    left: 5px;
}