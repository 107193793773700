$igInputTextBorder: 1px solid var(--ig-color-medium-blue);
$igInputTextHoverBorderColor: var(--ig-color-medium-blue);
$igInputTextBorderColor: var(--ig-color-medium-blue);
$igInputTextFontSize: 14px;
$igInputTextHeight: 40px;
$igInputTextPadding: 14px;
$igInputTextBorderWidth: 1px;
$igInputTextBorderRadius: $igBorderRadiusM;
$igInputTextFontFamily: var(--skin-font-family-headline);
$igInputTextFontWeight: $font-weight-light;
$igInputTextFontColor: var(--ig-color-label);
$igInputTextActiveShadow: 0 0 0 3px var(--ig-color-sky-blue40);
$igInputTextSecondaryBorderWidth: 1px;

//Rounded
$igInputTextRoundedBorderRadious: 22px;

/**
* Versions
*/

// Large
$igInputTextLargeFontSize: 16px;
$igInputTextLargeHeight: 50px;
$igInputTextLargeLineHeight: 16px;
$igInputTextLargePadding: 18px;
