@use "sass:math";

.p-autocomplete {
    .p-autocomplete-loader {
        right: nth($inputPadding, 2);
    }

    input[type=text], button {
      border: $inputBorder!important;
    }

    .p-button {
      color: var(--icons-color)!important;
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
        }
        .p-autocomplete-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .p-autocomplete-dropdown {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .p-autocomplete-multiple-container {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input();
        }

        .p-autocomplete-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;

            input {
                font-family: $fontFamily;
                font-size: $fontSize;
                color: var(--ig-text-color);
                padding: 0;
                margin: 0;
            }
        }

        .p-autocomplete-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            margin-bottom: math.div($inlineSpacing, 2);
            background: $highlightBg;
            color: $highlightTextColor;
            border-radius: $borderRadius;

            .p-autocomplete-token-icon {
                margin-left: $inlineSpacing;
            }
        }
    }

    &.p-error > .p-inputtext,
    &.p-invalid > .p-inputtext {
        @include invalid-input();
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include invalid-input();
}

.p-autocomplete-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-autocomplete-items {
        padding: $inputListPadding;

        .p-autocomplete-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;

            &:hover,&.p-focus, &:focus-within {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg;
            }

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }
        }
    }
}
