.p-overlaypanel {
  ul.drag-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      @include flexbox;
      @include flex-justify-content(space-between);
      padding: 0.4em 3px;
      margin-left: -3px;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        // COllision with Selectbox in dynamic overlay
        //  color: var(--primary-color);
      }

      i {
        cursor: pointer;
        color: var(--text-secondary-color);
      }

      &.cdk-drag-handle {
        cursor: move;
      }
    }
  }
}

.drag-drop-table .p-overlaypanel-content {
  //padding-left: 0;
  //padding-right: 0;
  //
  .cdk-drop-list {
    margin: 0 -1em 20px;
  }

  .cdk-drag.cdk-drag-handle {
    padding-left: 20px;
    padding-right: 20px;

    color: var(--ig-text-color);

    i {
      color: var(--icons-color);
    }

    margin: 0;

    &:hover, &:focus-within {
      text-decoration: none;
    }
  }
}

.p-overlaypanel.table-settings {
  span.headline {
    color: var(--text-secondary-color);
    text-transform: uppercase;
    font-size: $font-size-content-default;
    padding: 1em 0;
  }

  li {
    &.cdk-drag-placeholder {
      background: var(--ig-color-form-clickarea-background-hover);
      color: var(--ig-color-form-clickarea-foreground-light);
      text-decoration: none;
    }

    &.empty-placeholder {
      color: var(--ig-color-label);
      text-transform: uppercase;
      display: block;
      text-align: center;
    }
  }

  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.p-overlaypanel.row-actions {
  li {
    line-height: 1em;

    a {
      line-height: 1em;

      i {
        color: var(--primary-color);
        margin-right: 0.5em;
      }
    }
  }
}

.p-overlaypanel.overlaypanel--menu .p-overlaypanel-content {
  padding: 0;
}
