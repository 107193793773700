@import 'foundations_vars';

.p-chip {
  .pi-chip-remove-icon {
    color: $chipTextColor;
    &:hover {
      cursor: pointer;
    }
  }
}
