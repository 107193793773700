@mixin gicon-rules() {
  font-family: "gridscale_iconfont";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
}
$icons: (
  'a001_dashboard': '\e800',
  'a002_usage': '\e801',
  'a003_server': '\e802',
  'a004_storage': '\e803',
  'a005_network': '\e804',
  'a006_object_storage': '\e805',
  'a007_load_balancer': '\e806',
  'a008_gridsql': '\e807',
  'a009_gridstore': '\e808',
  'a010_firewall': '\e809',
  'a011_ip_manager': '\e80a',
  'a012_ssh_keys': '\e80b',
  'a013_api_keys': '\e80c',
  'a014_templates': '\e80d',
  'a015_iso_manager': '\e80e',
  'a016_marketplace': '\e80f',
  'a017_auditlog': '\e810',
  'a018_api_documentation': '\e811',
  'a019_home': '\e812',
  'a020_userstats': '\e813',
  'a021_api': '\e814',
  'a022_payment': '\e815',
  'a023_settings': '\e816',
  'a024_database': '\e817',
  'a025_user': '\e818',
  'a026_user_add': '\e819',
  'a027_alert': '\e81a',
  'a028_logo': '\e81b',
  'a029_search': '\e81c',
  'a030_email': '\e81d',
  'a031_magic_link': '\e81e',
  'a032_calendar': '\e81f',
  'a033_delete': '\e820',
  'a034_time': '\e821',
  'a035_speed_normal': '\e822',
  'a036_speed_high': '\e823',
  'a037_speed_insane': '\e824',
  'a038_target': '\e825',
  'a039_ack': '\e826',
  'a040_nack': '\e827',
  'a041_add': '\e828',
  'a042_power': '\e829',
  'a043_refresh': '\e82a',
  'a044_reload': '\e82b',
  'a045_compass': '\e82c',
  'a046_plane_normal': '\e82d',
  'a047_plane_high': '\e82e',
  'a048_plane_insane': '\e82f',
  'a049_plane_ultra': '\e830',
  'a050_login': '\e831',
  'a051_logout': '\e832',
  'a052_edit': '\e833',
  'a053_import': '\e834',
  'a054_doc_magic': '\e835',
  'a055_doc_legal1': '\e836',
  'a056_doc_legal2': '\e837',
  'a057_gift': '\e838',
  'a058_bug': '\e839',
  'a059_warning': '\e83a',
  'a060_pen': '\e83b',
  'a061_pen_writing': '\e83c',
  'a062_check': '\e83d',
  'a063_back': '\e83e',
  'a064_prev': '\e83f',
  'a065_add_outline': '\e840',
  'a066_chevron_right': '\e841',
  'a067_chevron_left': '\e842',
  'a068_chevron_down': '\e843',
  'a069_chevron_up': '\e844',
  'a070_tiles_big': '\e845',
  'a071_tiles_small': '\e846',
  'a072_list': '\e847',
  'a073_column': '\e848',
  'a074_minimize': '\e849',
  'a075_sort': '\e84a',
  'a076_menu': '\e84b',
  'a077_info': '\e84c',
  'a078_help': '\e84d',
  'a079_visible': '\e84e',
  'a080_invisible': '\e84f',
  'a081_star': '\e850',
  'a082_setting': '\e851',
  'a083_export_basket': '\e852',
  'a084_back_alt': '\e853',
  'a085_internet': '\e854',
  'a086_disc': '\e855',
  'a087_delete_alt': '\e856',
  'a088_search_alt': '\e857',
  'a089_loader': '\e858',
  'a090_power_alt': '\e859',
  'a091_rollback': '\e85a',
  'a092_time': '\e85b',
  'a093_snapshot': '\e85c',
  'a094_thumb_up': '\e85d',
  'a095_thumb_down': '\e85e',
  'a096_console': '\e85f',
  'a097_pen_alt': '\e860',
  'a098_check_alt': '\e861',
  'a099_add_alt': '\e862',
  'a100_server_alt': '\e863',
  'a101_storage_alt': '\e864',
  'a144_virtual_locations': '\e865',
  'a102_network_alt': '\e866',
  'a103_ram': '\e867',
  'a104_cpu': '\e868',
  'a105_backupcenter': '\e869',
  'a106_linkextern': '\e86a',
  'a107_export': '\e86b',
  'a108_restore': '\e86c',
  'a109_close_delete': '\e86d',
  'a110_download': '\e86e',
  'a111_download_alt': '\e86f',
  'a112_new_document': '\e870',
  'a113_jumpin': '\e871',
  'a114_jumpin_fill': '\e872',
  'a115_kubernetes': '\e873',
  'a116_star_sharp_outline': '\e874',
  'a117_star_sharp_solid': '\e875',
  'a118_3dotmenu': '\e876',
  'a119_warning_alt': '\e877',
  'a120_info_outline': '\e878',
  'a121_user_added': '\e879',
  'a122_visible_outline': '\e87a',
  'a123_invisible_outline': '\e87b',
  'a124_copy': '\e87c',
  'a125_copy_alt': '\e87d',
  'a126_lock': '\e87e',
  'a127_public_pulldown': '\e87f',
  'a128_global_pulldown': '\e880',
  'a129_not_public_pulldown': '\e881',
  'a130_visible_pulldown': '\e882',
  'a131_invisible_pulldown': '\e883',
  'a132_toggle_on': '\e884',
  'a133_toggle_off': '\e885',
  'a134_select_project': '\e886',
  'a135_accept_invitation': '\e887',
  'a136_console_light': '\e888',
  'a137_time_light': '\e889',
  'a138_check_circled': '\e88a',
  'a139_check_alt02': '\e88b',
  'a140_projects': '\e88c',
  'a141_projectmanagement': '\e88d',
  'a142_create_project': '\e88e',
  'a143_usermanagement': '\e88f',
  'a145_check_alt03': '\e890',
  'a146_not_available': '\e891',
  'a147_add_larger': '\e892',
  'a148_delete_smaller': '\e893',
  'a149_location_explorer': '\e894',
  'a150_location': '\e895',
  'a151_location_map': '\e896',
  'a152_location_map_outline': '\e897',
  'a153_link': '\e898',
  'a154_help': '\e899',
  'a155_help_alt': '\e89a',
  'a156_file_system': '\e89b',
  'a157_chevron_bold_right': '\e89c',
  'a158_chevron_bold_left': '\e89d',
  'a159_chevron_bold_down': '\e89e',
  'a160_chevron_bold_up': '\e89f',
  'a161_disconnect': '\e8a0',
  'a162_refresh': '\e8a1',
  'a163_snapshot_light': '\e8a2',
  'a164_drag': '\e8a3',
  'a165_hybrid_core-kopie': '\e8a4',
  'a166_support-ticket': '\e8a5',
  'a167_cleanup': '\e8a6',
  'a168_settings_backup': '\e8a7',
  'a169_fullscreen': '\e8a8',
  'a170_exit_fullscren': '\e8a9',
  'a171_pin': '\e8aa',
  'a172_open_new_window': '\e8ab',
  'a173_restore_window': '\e8ac',
  'a174_cloud_panel': '\e8ad',
  'a175_panel': '\e8ae',
  'a176_calculator': '\e8af',
  'a177_unlocked': '\e8b0',
  'a178_publish': '\e8b1',
  'a179_2_faktor_auth': '\e8b2',
  'a180_user_directory': '\e8b3',
  'a181_branding': '\e8b4',
  'a182_managed_apps': '\e8b5',
  'a183_import_app': '\e8b6',
  'a184_improved': '\e8b7',
  'a185_info-icon': '\e8b8',
  'a186_abrechnung': '\e8b9',
  'a187_prozente': '\e8ba',
  'a188_detailview': '\e8bb',
  'a189_maintenance': '\e8bc',
  'a190_hybrid_core_ct': '\e8bd',
  'a191_hybrid_core_ct_bold': '\e8be',
  'a192_hybrid_core_bold': '\e8bf',
  'a193_cloud_panel_bold': '\e8c0',
  'a194_user_directory_bold': '\e8c1',
  'a195_branding_bold': '\e8c2',
  'a196_api_keys_bold': '\e8c3',
  'a197_calculator_bold': '\e8c4',
  'a198_abrechnung_bold': '\e8c5',
  'a199_time_bold': '\e8c6',
  'a200_recommend': '\e8c7',
  'a201_minimize_menu': '\e8c8',
  'a202_maximize_menu': '\e8c9',
  'a203_settings_bold': '\e8ca',
  'a204_usermanagement_bold-1': '\e8cb',
  'a205_logout_bold': '\e8cc',
  'a206_payment_bold': '\e8cd',
  'a207_document_bold': '\e8ce',
  'a208_lightmode': '\e8cf',
  'a209_darkmode': '\e8d0'
);

//* **** NO MORE CHANGES FOR FONT UPDATES NEEDED FROM HERE **** **/

// map additional classes to the icons
$gicons-map: (
  "a001_dashboard": "gicon-dashboard",
  "a002_usage": "gicon-usage",
  "a003_server": (
    "name": "gicon-server",
    "transform": "scale(1.8)"
  ),
  "a004_storage": "gicon-storage",
  "a005_network": "gicon-network",
  "a006_object_storage": (
    "name": "gicon-object_storage",
    "transform": "scale(1.4)"
  ),
  "a007_load_balancer": (
    "name": "gicon-load_balancer",
    "transform": "scale(1.4)"
  ),
  "a008_gridsql": (
    "name": "gicon-gridsql",
    "transform": "scale(1.4)"
  ),
  "a009_gridstore": (
    "name": "gicon-gridstore",
    "transform": "scale(1.4)"
  ),
  "a010_firewall": (
    "name": "gicon-firewall",
    "transform": "scale(1.4)"
  ),
  "a011_ip_manager": (
    "name": "gicon-ip_manager",
    "transform": "scale(1.4)"
  ),
  "a012_ssh_keys": (
    "name": "gicon-ssh_keys",
    "transform": "scale(1.4)"
  ),
  "a013_api_keys": (
    "name": "gicon-api_keys",
    "transform": "scale(1.4)"
  ),
  "a014_templates": "gicon-templates",
  "a015_iso_manager": "gicon-iso_manager",
  "a016_marketplace": (
    "name": "gicon-marketplace",
    "transform": "scale(1.8)"
  ),
  "a017_auditlog": "gicon-auditlog",
  "a018_api_documentation": [ "gicon-api_documentation",
  "gicon-documentation" ],
  "a024_database": (
    "name": "gicon-database",
    "transform": "scale(1.4)"
  ),
  "a031_magic_link": "gicon-magic_link",
  "a033_delete": (
    "name": "gicon-delete",
    "transform": "scale(1.8)"
  ),
  "a034_time": (
    "name": "gicon-time",
    "transform": "scale(1.4)"
  ),
  "a038_target": (
    "name": "gicon-target",
    "transform": "scale(1.4)"
  ),
  "a039_ack": "gicon-ack",
  "a041_add": [ "gicon-add",
  (
      "name": "gicon-close",
      "transform": "rotate(45deg)"
    )
    ],
  "a045_compass": "gicon-compass",
  "a046_plane_normal": "gicon-plane_normal",
  "a047_plane_high": "gicon-plane_high",
  "a048_plane_insane": "gicon-plane_insane",
  "a049_plane_ultra": "gicon-plane_ultra",
  "a050_login": "gicon-login",
  "a052_edit": "gicon-edit",
  "a059_warning": "gicon-warning",
  "a063_back": "gicon-back",
  "a110_download": "gicon-download",
  "a062_check": "gicon-check",
  "a065_add_outline": [ "gicon-plus2",
  "gicon-add_outline" ],
  "a066_chevron_right": "gicon-chevron_right",
  "a068_chevron_down": "gicon-chevron_down",
  "a070_tiles_big": "gicon-tiles_big",
  "a071_tiles_small": "gicon-tiles_small",
  "a073_column": (
    "name": "gicon-column",
    "transform": "scale(1.8)"
  ),
  "a074_minimize": [
    (
      "name": "gicon-minimize",
      "transform": "scale(1.8)"
    ),
  (
      "name": "gicon-maximize",
      "transform": "rotate(180deg) scale(1.8)"
    )
    ],
  "a082_setting": "gicon-setting",
  "a083_export_basket": (
    "name": "gicon-export_basket",
    "transform": "scale(1.8)"
  ),
  "a084_back_alt": "gicon-back_alt",
  "a085_internet": (
    "name": "gicon-internet",
    "transform": "scale(1.8)"
  ),
  "a086_disc": (
    "name": "gicon-disc",
    "transform": "scale(1.8)"
  ),
  "a087_delete_alt": "gicon-delete_alt",
  "a088_search_alt": "gicon-search_alt",
  "a090_power_alt": "gicon-power_alt",
  "a091_rollback": "gicon-rollback",
  "a093_snapshot": (
    "name": "gicon-snapshot",
    "transform": "scale(1.8)"
  ),
  "a096_console": (
    "name": "gicon-console",
    "transform": "scale(1.8)"
  ),
  "a098_check_alt": "gicon-check_alt",
  "a099_add_alt": "gicon-add_alt",
  "a100_server_alt": (
    "name": "gicon-server_alt",
    "transform": "scale(1.8)",
    "display": "inline-block"
  ),
  "a101_storage_alt": (
    "name": "gicon-storage_alt",
    "transform": "scale(1.8)",
    "display": "inline-block"
  ),
  "a102_network_alt": (
    "name": "gicon-network_alt",
    "transform": "scale(1.8)",
    "display": "inline-block"
  ),
  "a103_ram": (
    "name": "gicon-ram",
    "transform": "scale(1.4)"
  ),
  "a104_cpu": (
    "name": "gicon-cpu",
    "transform": "scale(1.8)"
  ),
  "a105_backupcenter": "gicon-backupcenter",
  "a115_kubernetes": (
    "name": "gicon-kubernetes",
    "transform": "scale(1.4)"
  ),
  "a116_star_sharp_outline": "gicon-star_sharp_outline",
  "a117_star_sharp_solid": "gicon-star_sharp_solid",
  "a118_3dotmenu": "gicon-3dotmenu",
  "a119_warning_alt": "gicon-warning_alt",
  "a120_info_outline": "gicon-info_outline",
  "a121_user_added": "gicon-user_added",
  "a122_visible_outline": "gicon-visible_outline",
  "a123_invisible_outline": "gicon-invisible_outline",
  "a124_copy": "gicon-copy",
  "a125_copy_alt": "gicon-copy_alt",
  "a126_lock": "gicon-lock",
  "a127_public_pulldown": "gicon-public_pulldown",
  "a128_global_pulldown": "gicon-global_pulldown",
  "a129_not_public_pulldown": "gicon-not_public_pulldown",
  "a130_visible_pulldown": "gicon-visible_pulldown",
  "a131_invisible_pulldown": "gicon-invisible_pulldown",
  "a132_toggle_on": "gicon-toggle_on",
  "a133_toggle_off": "gicon-toggle_off",
  "a134_select_project": "gicon-select-project",
  "a135_accept_invitation": "gicon-accept_invitation",
  "a136_console_light": "gicon-console_light",
  "a137_time_light": "gicon-time_light",
  "a138_check_circled": "gicon-check_circled",
  "a139_check_alt02": "gicon-check_alt02",
  "a140_projects": "gicon-projects",
  "a141_projectmanagement": "gicon-projectmanagement",
  "a142_create_project": "gicon-create_project",
  "a143_usermanagement": "gicon-usermanagement"
);

@mixin print-gicon($giconData, $code) {
  .#{map-get($giconData, "name")} {
    &:before {
      content: $code;
      @each $giconDataKey, $giconDataVal in $giconData {
        @if $giconDataKey != "name" {
          #{$giconDataKey}: #{$giconDataVal};
        }
      }
    }
  }
}

@mixin print-gicon($giconData, $code) {
  .#{map-get($giconData, "name")} {
    &:before {
      content: $code;
      @each $giconDataKey, $giconDataVal in $giconData {
        @if $giconDataKey != "name" {
          #{$giconDataKey}: #{$giconDataVal};
        }
      }
    }
  }
}

$gicons-map-reverse: ();

@each $name, $code in $icons {
  // print additonal gitem classes associated to icon
  @if map-has-key($gicons-map, $name) {
    $gicon: map-get($gicons-map, $name);
    $giconCls: $gicon;
    @if type-of($gicon) != "list" {
      $gicon: [ $gicon ];
    }

    @each $giconData in $gicon {
      @if type-of($giconData) != "map" {
        $giconData: (
          name: $giconData
        );
      }
      @include print-gicon($giconData, $code);
      $gicons-map-reverse: map-merge(
        $gicons-map-reverse,
        (
          map-get($giconData, "name"): $name
        )
      );
    }
  }

  // print default icon class
  .icon-#{$name}:before {
    content: $code;
  }
}

// print only the "content:" rule
@mixin gicon-content($icon) {
  @if map-has-key($icons, $icon) {
    content: map-get($icons, $icon);
  } @else {
    // try to find in gicon map
    $icon: "gicon-#{$icon}";
    @if map-has-key($gicons-map-reverse, $icon) {
      content: map-get($icons, map-get($gicons-map-reverse, $icon));
    } @else {
      @warn 'cannot find icon key #{$icon}';
    }
  }
}

// apply all gicon rules, optional the content rule if $icon is given
@mixin gicon($icon: null) {
  @if $icon {
    @include gicon-content($icon);
  }

  @include gicon-rules();
}

// apply all gicon rules without extra paddings, optional the content rule if $icon is given
@mixin gicon-inline($icon: null) {
  @include gicon($icon);

  margin-right: 0;
  margin-left: 0;
  width: auto;
}
